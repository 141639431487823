import React from "react"
import { graphql } from 'gatsby'
import { PortableText } from "@portabletext/react"
import Serializers from '../components/serializers'
import {GatsbyImage} from 'gatsby-plugin-image'
import styled from "@emotion/styled"

import Layout from '../components/layout'

const Main = styled.main`
width: 80%;
margin: 0 auto;
img {
  max-width: 100%;
}
`

const Textwrap = styled.div`
    margin: 0 auto 24px auto;
    p {
      font-size: 1.25rem;
      text-align: left;
      width: 100%;
      margin-bottom: 50px;
      a {
        text-decoration: none;
        color: #000;
        transition: all 0.3s ease;
        &:hover {
          color: rgb(112,112,112);
        }
      }
    }
`

const IndexPage = ({data}) => {
  return (
    <Layout>
      {data.allSanityHomePage.nodes.map(item => (
        <Main>
        <Textwrap>
        <h2>{item.title}</h2>
        <PortableText
        value={item._rawText}
        components={Serializers}
        />
        </Textwrap>
        <GatsbyImage image={item.image[0].image.asset.gatsbyImageData} alt='image'/>
        </Main>
      ))}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
query {
  allSanityHomePage(limit: 1) {
    nodes {
      title
      _rawText
      image {
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`